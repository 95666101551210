import React from "react";
import "./footer.css";

function OpenGithub() {
    window.open("https://github.com/camicollao", "_blank");
}

function OpenLinkedIn() {
    window.open("https://www.linkedin.com/in/camilacollao/", "_blank");
}

const Footer = () => {
    return (
        <footer className="footer">
            <p style={{padding: "10px"}}>¡Gracias por visitarme!</p>
            <p>© 2024 Camila Collao</p>
            <button onClick={OpenGithub} className="Button"><i class="fa-brands fa-github"></i></button>
            <button onClick={OpenLinkedIn} className="Button"><i class="fa-brands fa-linkedin"></i></button>
        </footer>
    );
}

export default Footer;