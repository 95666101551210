import React from 'react';
import './work.css';
import Popup from 'reactjs-popup';
import biblioteca from '../../assets/biblioteca.gif';
import registros from '../../assets/php-registros.gif';
import todolist from '../../assets/todolist.png';
import Box from '@mui/material/Box';



function OpenGithubLibrary() {
    window.open("https://github.com/camicollao/Intranet-Biblioteca", "_blank");
}

function OpenDemoLibrary() {
    window.open("http://camilacollao.pythonanywhere.com/", "_blank");   
}

function OpenGithubFrontTodoList() {
    window.open("https://github.com/camicollao/Front-End-Todo-List-Angular", "_blank");
}

function OpenGithubBackTodoList() {
    window.open("https://github.com/camicollao/To-do-list-Nestjs", "_blank");
}

function OpenGithubPhp() {
    window.open("https://github.com/camicollao/Php-CRUD", "_blank");
}

function OpenDemoPhp() {
    window.open("https://camilacollaocrud.000webhostapp.com/", "_blank");
}




const Works = () => {
    return (
        <section id="works">
            <h2 className='WorksTitle'>Mis Proyectos</h2>
            <span className='WorksDesc'>He desarrollado soluciones tecnologicas, tanto como parte de mi formación académica, mi practica profesional, proyectos  para clientes y mis proyectos personales. En estos proyectos he sido parte de la planificación de las funcionalidades, la selección de tecnologías, desarrollo de la aplicación y despliegue utilizando tecnologías de la nube. Incluyendo API Rest, optimización de rendimiento, validación de contenido y protección de datos.</span>
            <div className='WorksImgs'>
                <Popup trigger={<img src={biblioteca} alt='img1' className='WorksImg'/>} modal nested>
                {close => ( 
                         <div className="modal">
                            <button className="close" onClick={close}>
                                &times;
                            </button>        
                            <div className="header"> Intranet de Biblioteca </div>
                            <div className="content">          
                                {' '}
                                Planifiqué, programé y desplegué una página web de la intranet de la biblioteca de una empresa.
                                o Proyecto grupal de final de carrera utilizando metodologías ágiles.
                                o Utilización de patrón MVC y Azure Boards.
                                o Inicio y cierre de sesion del perfil de Administrador y Usuario.
                                o Agregar, editar, ver y eliminar usuarios y libros a través del perfil administrador.
                                o Realizar préstamos de libros disponibles a través del perfil de usuario
                                <br/>
                                <Box sx={{ p: 2, display: 'flex', gap: '10px'}}>
                                    Tecnologias utilizadas:
                                    <i class="fa-brands fa-django"><br/><span>Django</span></i>
                                    <i class="fa-brands fa-html5" fixedWidth><br/><span>HTML</span></i>
                                    <i class="fa-brands fa-css3" fixedWidth><br/><span>CSS</span></i>
                                    <i class="fa-brands fa-js" fixedWidth><br/><span>JavaScript</span></i>
                                    <i class="fa-brands fa-bootstrap"><br/><span>Bootstrap</span></i>
                                </Box>
                            </div>
                            <div className="actions">
                                <button className="button" onClick={OpenGithubLibrary}>
                                    Ver en GitHub
                                </button>
                                <button className="button" onClick={OpenDemoLibrary}>
                                    Demo
                                </button>
                            </div>      
                        </div>    )}
                </Popup>
                <Popup trigger={<img src={todolist} alt='img1' className='WorksImg'/>} modal nested>
                {close => ( 
                         <div className="modal">
                            <button className="close" onClick={close}>
                                &times;
                            </button>        
                            <div className="header"> Sitio web de lista de quehaceres </div>
                            <div className="content">          
                                {' '}
                                ● Página web que programé tanto Front-End como Back-End como forma de explorar nuevas tecnologías.
                                o Su propósito es ver, crear, modificar y eliminar tareas por hacer.
                                o Registro e inicio de sesión de usuarios.
                                o Utilización de criptografía para proteger datos personales de usuarios
                                <Box sx={{ p: 2, display: 'flex', gap: '10px'}}>
                                    Tecnologias utilizadas:
                                    <i class="fa-regular fa-hard-drive"><br/><span>MySQL</span></i>
                                    <i class="fa-brands fa-node-js"><br/><span>NodeJS</span></i>
                                    <i class="fa-brands fa-nest-js"><br/><span>NestJS</span></i>
                                    <i class="fa-brands fa-typescript"><br/><span>Typescript</span></i>
                                    <i class="fa-brands fa-docker"><br/><span>Docker</span></i>
                                </Box>
                            </div>
                            <div className="actions">
                                <button className="button" onClick={OpenGithubFrontTodoList}>
                                    Ver en GitHub Front-End
                                </button>
                                <button className="button" onClick={OpenGithubBackTodoList}>
                                    Ver en GitHub Back-End
                                </button>
                                <button className="button" onClick={OpenGithubLibrary}>
                                    Demo
                                </button>
                            </div>      
                        </div>    )}
                </Popup>
                <Popup trigger={<img src={registros} alt='img1' className='WorksImg'/>} modal nested>
                {close => ( 
                         <div className="modal">
                            <button className="close" onClick={close}>
                                &times;
                            </button>        
                            <div className="header"> Formulario de registros </div>
                            <div className="content">          
                                {' '}
                                ● Reto personal que me permitió aprender y aplicar conocimientos principalmente de Back-End.
                                o Permite ver, crear, modificar y eliminar información personal de personas.
                                o Formulario de registro y almacenamiento de información.
                                <Box sx={{ p: 2, display: 'flex', gap: '10px'}}>
                                    Tecnologias utilizadas:
                                    <i class="fa-regular fa-hard-drive"><br/><span>MySQL</span></i>
                                    <i class="fa-brands fa-html5" fixedWidth><br/><span>HTML</span></i>
                                    <i class="fa-brands fa-bootstrap"><br/><span>Bootstrap</span></i>
                                    <i class="fa-brands fa-php"><br/><span>Php</span></i>
                                    <i class="fa-brands fa-jquery"><br/><span>JQuery</span></i>
                                </Box>
                            </div>
                            <div className="actions">
                                <button className="button" onClick={OpenGithubPhp}>
                                    Ver en GitHub
                                </button>
                                <button className="button" onClick={OpenDemoPhp}>
                                    Demo
                                </button>
                            </div>      
                        </div>    )}
                </Popup>
            </div>
        </section>

    );
}

export default Works;