import React from "react";
import './navbar.css';
import { Link } from 'react-scroll';
// import logo.png from '../../assets/logo.png';

const Navbar = () => {
    return (
        <nav className="navbar">
            <div className="desktopMenu">
                <Link activeClass="active" to="intro" spy={true}
                smooth={true} offset={-100} duration={500}  className="desktopMenuList">Home</Link>
                <Link activeClass="active" to="aboutme" spy={true}
                smooth={true} offset={-100} duration={500}  className="desktopMenuList">Sobre mi</Link>
                <Link activeClass="active" to="works" spy={true}
                smooth={true} offset={-100} duration={500}  className="desktopMenuList">Proyectos</Link>
            </div>
            <button className="desktopMenuBtn" onClick={() => (
                document.getElementById("contact").scrollIntoView({behavior: 'smooth'})
            )}>Contactame</button>
        </nav>
    )
}

export default Navbar;