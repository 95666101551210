import Navbar from './components/navbar/navbar';
import Intro from './components/intro/intro';
import AboutMe from './components/aboutme/aboutme';
import Works from './components/works/work';
import Contact from './components/contact/contact';
import Footer from './components/footer/footer';
function App() {
  return (
    <div className="App">
      <Navbar />
      <Intro />
      <AboutMe />
      <Works />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
