import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import "./contact.css";
import Alert from '@mui/material/Alert';
import { Snackbar } from '@mui/material';

const Contact = () => {
    const form = useRef();
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('success');

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs
        .sendForm('service_4b2tgqw', 'template_tb1o2bn', form.current, {
          publicKey: '3nlQpOFSowIa8W5Wd',
        })
        .then(
          () => {         
            setAlertMessage("Mensaje enviado, ¡Te responderé pronto!");
            setAlertSeverity('success');
            setAlertOpen(true); 
          },
          (error) => {
            setAlertMessage("Error al enviar el mensaje", error.text);
            setAlertSeverity('error');
            setAlertOpen(true);
          },
        );
    };

    
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
          return;
      }
      setAlertOpen(false);
    };

    return (
        <section className="contactPage">
          <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={alertSeverity} sx={{ width: '100%' }}>
                {alertMessage}
            </Alert>
          </Snackbar> 
        <div id="contact">
            <h2 className="contactTitle">Contacto</h2>
            <p className="contactDesc">
                Si deseas contactarme, puedes hacerlo a traves de mi numero o enviandome un correo.
            </p>
            <form className="contactForm" ref={form} onSubmit={sendEmail}>
                <input type="text" placeholder="Tu nombre" className="name" name='name' required/>
                <input type="email" placeholder="Tu correo" className="email" name='email' required/>
                <textarea placeholder="Deja tu mensaje aqui" className="msg" rows="5" name='msg' required></textarea>
                <button type="submit" value="submit" className="btncontact">Mandar mensaje</button>
            </form>
        </div>

    </section>
    );
}

export default Contact;