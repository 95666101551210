import React from "react";
import './intro.css';
import bg from '../../assets/Designerbg.png';
import { Link } from 'react-scroll';
function OpenGithub() {
    window.open("https://github.com/camicollao", "_blank");
}

const Intro = () => {
    return (
        <section id="intro">
            <div className="introContent">
                <h1 className="introTitle">¡Hola!</h1><span className="introName">Soy Camila Collao</span>
                <br/>
                <p className="introDesc">Desarrolladora de Software</p>
                <p className="introPara">¡Tambien freelancer! Creo aplicaciones tecnologicas, como paginas web.</p>
                <div className="introLinks">
                <Link activeClass="active" to="works" spy={true}
                smooth={true} offset={-100} duration={500} >
                    <button className="btnInfo">Conoce mis proyectos</button>
                </Link>
                    <button onClick={OpenGithub} className="Button"><i class="fa-brands fa-github fa-xl"></i></button>
                </div>
            </div>
            <img src={bg} alt="ProfileImagen" className="ProfileImg"/>
        </section>
    )
}

export default Intro;