import React from 'react';
import './aboutme.css';
import pincel from '../../assets/pinceles-de-maquillaje.png';
import artilugio from '../../assets/artilugio.png';
import pantalla from '../../assets/pantalla-del-monitor.png';
import recursos from '../../assets/recursos-tecnologicos.png';

const AboutMe = () => {
    return (
        <section id="aboutme">
            <h1 className="AboutMeTitle">Sobre mi</h1>
            <span className='AboutMeDesc'>Desarrolladora con experiencia creando soluciones tecnológicas web y trabajando con equipos ágiles. Dedicada al trabajo de desarrollo web tanto del lado del cliente como del servidor, pero con un gran interés en las bases de datos y la tecnología en la nube. Aporta a los equipos con su proactividad, responsabilidad y versatilidad.</span>
            <div className='AboutMeBars'>
                <div className='AboutMeBar'>
                    <img src={pincel} alt='Pincel' className='AboutMeImg'/>
                    <div className='AboutMeBarText'>
                        <h2 className='AboutMeBarTitle'>Diseño de la pagina web</h2>
                        <p>Creo soluciones de parte del cliente que busquen hacer paginas funcionales y bonitas. Utilizo distintos lenguajes dependiendo de el presupuesto y necesidades. </p>
                    </div>

                </div>
                <div className='AboutMeBar'>
                    <img src={recursos} alt='Pincel' className='AboutMeImg'/>
                    <div className='AboutMeBarText'>
                        <h2 className='AboutMeBarTitle'>Pruebas y parte empresarial</h2>
                        <p>Me gusta desarrollar codigo escalable y seguro que permita flexibilidad para adaptarse con el tiempo para cuando se necesiten crear cambios.</p>
                    </div>
                </div>
                <div className='AboutMeBar'>
                    <img src={pantalla} alt='Pincel' className='AboutMeImg'/>
                    <div className='AboutMeBarText'>
                        <h2 className='AboutMeBarTitle'>Diseño de soluciones</h2>
                        <p>Me encargo de que las funcionalidades sean personalizadas y unicas de acuerdo a lo que el cliente necesita.</p>
                    </div>
                </div>
                <div className='AboutMeBar'>
                    <img src={artilugio} alt='Pincel' className='AboutMeImg'/>
                    <div className='AboutMeBarText'>
                        <h2 className='AboutMeBarTitle'>Personalizacion y soporte</h2>
                        <p>Ofrezco un acompañamiento a mis clientes despues de la entrega del producto para verificar si cumple completamente con lo que desea.</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AboutMe;